import React from 'react'
import { useLang } from './locals'
import { IntlProvider } from 'react-intl'

// import deMessages from './messages/de.json'
import enMessages from './messages/en.json'
// import esMessages from './messages/es.json'
// import frMessages from './messages/fr.json'
// import jaMessages from './messages/ja.json'
// import zhMessages from './messages/zh.json'
import arMessages from './messages/ar.json'

const allMessages = {
    // de: deMessages,
    en: enMessages,
    // es: esMessages,
    // fr: frMessages,
    // ja: jaMessages,
    // zh: zhMessages,
    ar: arMessages
}

const I18nProvider = ({ children }) => {
    const locale = useLang()
    const messages = allMessages[locale]
    return (
        <IntlProvider locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    )
}

export { I18nProvider }
