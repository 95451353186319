
import './App.css';
import { Navigation } from './screens/app-navigation';
import { LocalProvider } from './intl/locals';
import { I18nProvider } from './intl/i18Provider';

function App() {
  return (
    <LocalProvider>
      <I18nProvider>
        <Navigation />
      </I18nProvider>
    </LocalProvider>
  );
}

export default App;
