import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Outlet, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { setLanguage, useLang } from '../intl/locals';
import { useTranslation } from '../intl/helper';

export const NavBar = () => {
    const navigate = useNavigate()
    const lang = useLang();
    console.log(lang)
    const t = useTranslation()
    return (
        <>
            <Navbar bg={"white"} expand="lg">
                <Container >
                    <Navbar.Brand onClick={() => navigate('/')}><img height={70} src={require('../assets/images/logo.png')} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto"  >
                        <Nav.Link onClick={() => navigate('/')} className='text-center fw-bolder text-primary fs-6'>
                                {t('home')}
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/welcome')} className='text-center fw-bolder text-primary fs-6'>
                                {t('welcome')}
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/about')} className='text-center text-primary fw-bolder fs-6'>{t('about')}</Nav.Link>
                            <Nav.Link onClick={() => navigate('/contact')} className='text-center text-primary fw-bolder fs-6'>{t('contact.head')}</Nav.Link>
                            <Nav.Link onClick={() => {
                                switch (lang) {
                                    case 'en':
                                        setLanguage('ar');
                                        break
                                    case 'ar':
                                        setLanguage('en')
                                        break
                                }
                            }} className='text-center text-primary '>{
                                    lang === 'en' ?
                                        'العربية'
                                        : 'ENGLISH'
                                }
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet />
        </>
    )
}
