import React from "react";
import { useTranslation } from "../intl/helper";
import { useLang } from "../intl/locals";

export const ContactScreen = () => {
    const t = useTranslation()
    const lang = useLang()
    return (
        <div dir={lang === 'ar' ? 'rtl' : ''}>
            <div className="container">
                <div className="col-lg-6 block-title d-flex flex-column justify-content-center " style={{
                    height: '25vh'
                }}>
                    <h5 className="align-left display-6 fw-bold text-primary"><strong>{t('contact.head')}</strong>
                    </h5>
                    <h6 className="mbr-section-subtitle mbr-fonts-style lead ">
                        {t('contact.description')}
                    </h6>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12 row">
                        <div className="col-lg-6 my-1 py-2">
                            <div className="fs-4">Location​</div>
                            <div className="fs-6"> 7465 Umar Bin Nadiah Street,Office 102 ,</div> 
                            <div>Mamoun Plaza ,An Nuzhah district,</div>
                            <div>Madinah Road, Jeddah, KSAPO Box - 23532</div>
                            
                        </div>
                        <div className="col-lg-6 my-1 py-2">
                            <div className="fs-4">Office Hours</div>
                            <div className="fs-6">Sunday-Thursday​</div>
                            <div>09.00-17.00​</div>
                        </div>
                        <div className="col-lg-6 my-1 py-2">
                            <div className="fs-4">Get In Touch</div>
                            <div className="fs-6">(+966) 50 471 2627​</div>
                           
                        </div>
                        <div className="col-lg-6 my-1 py-2">
                            <div className="fs-4">More Information​</div>
                            <div className="fs-6">www.uniqedestination.com.sa​</div>
                            <div>info@uniqedestination.com.sa​</div>
                        </div>
                    </div>
                    <div className="col-lg-6 my-auto md-pb mbr-form" data-form-type="formoid">
                        <form className="mbr-form form-with-styler"
                            data-form-title="Form Name">

                            <div className="row">
                                <div hidden="hidden" data-form-alert="" className="alert alert-success col-12">
                                    Thanks for filling out
                                    the form!
                                </div>
                                <div hidden="hidden" data-form-alert-danger="" className="alert alert-danger col-12">
                                    Oops...! some
                                    problem!
                                </div>
                            </div>
                            <div className="dragArea row">
                                <div className="col-lg-6 col-md-12 col-sm-12  form-group mb-3" data-for="First Name">
                                    <input type="text"
                                        style={{
                                            outline: 'none !important'
                                        }}
                                        name="First Name" placeholder={t('first_name')}
                                        data-form-field="First Name" className="form-control display-7"
                                        id="First Name-forms3-40" />
                                </div>
                                <div className="col-lg-6 shadow-none outline-none col-md-12 col-sm-12 form-group mb-3" data-for="Last Name">
                                    <input type="text" name="Last Name" placeholder={t('last_name')} data-form-field="Last Name"
                                        className="form-control display-7"
                                        id="Last Name-forms3-40" />
                                </div>
                                <div data-for="Email" className="col-lg-6 col-md-12 col-sm-12 form-group mb-3">
                                    <input type="email" name="Email" placeholder={t('email')} data-form-field="Email"
                                        className="form-control display-7" id="Email-forms3-40" />
                                </div>
                                <div data-for="Phone" className="col-lg-6 col-md-12 col-sm-12 form-group mb-3">
                                    <input type="text" name="Phone" placeholder={t('phone')} data-form-field="Phone"
                                        className="form-control display-7" id="Phone-forms3-40" />
                                </div>
                                <div data-for="Message" className="col-lg-12 col-md-12 col-sm-12 form-group mb-3">
                                    <textarea name="Message" placeholder={t('msg')} data-form-field="Message"
                                        className="form-control display-7"
                                        id="Message-forms3-40"></textarea>
                                </div>
                                <div className="col-auto"><button type="submit" className="btn btn-primary display-4">
                                    {t('mgsBtn')}</button></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
