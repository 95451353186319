import React from "react";
import { useTranslation } from "../intl/helper";
import { useLang } from "../intl/locals";


export const AboutScreen = () => {
    const t = useTranslation()
    const lang = useLang()
    return (
        <div dir={lang === 'ar' ? 'rtl' : ''}>
            <div
                className=" d-flex justify-content-center align-items-center "
                style={{
                    backgroundImage: `url(${require('../assets/images/newspaper.png')})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '50vh',
                    backgroundPosition: '50% 50%'

                }}
            >
                <h1 className=" my-1 display-1 text-center col-sm-10 text-white">{t('WHY.HEADING')}</h1>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="card border-0  p-3 col-12 col-md-6 col-lg-3">
                        <div className="card-img pb-5">
                            <span className="mbri-bootstrap mbr-iconfont"></span>
                        </div>
                        <div className="" i18n-key="why-para1">
                            <p
                                className="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">
                                {t('WHY.PARAGRAPH1')}
                                {t('WHY.PARAGRAPH2')}
                            </p>
                        </div>
                    </div>

                    <div className="card border-0 p-3 col-12 col-md-6 col-lg-3">
                        <div className="card-img pb-5">
                            <span className="mbri-touch mbr-iconfont"></span>
                        </div>
                        <div className="card-box">

                            <p i18n-key="why-para2"
                                className="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">
                                {t('WHY.PARAGRAPH3')}
                            </p>
                        </div>
                    </div>

                    <div className="card border-0 p-3 col-12 col-md-6 col-lg-3">
                        <div className="card-img pb-5">
                            <span className="mbri-responsive mbr-iconfont"></span>
                        </div>
                        <div className="card-box">
                            <p i18n-key="why-para3"
                                className="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">
                                {t('WHY.PARAGRAPH4')}
                                {/* Price vs. Quality: Lowest market rates with high quality as we keep an edge over our
                                competitors by providing our clients with the best prices and services. We strive for value;
                                quality is the goal of our work.<br />
                                Providing exceptional customer service will set us apart.  */}
                            </p>
                        </div>
                    </div>

                    <div className="card border-0 p-3 col-12 col-md-6 col-lg-3">
                        <div className="card-img pb-5">
                            <span className="mbri-desktop mbr-iconfont"></span>
                        </div>
                        <div className="card-box">

                            <p i18n-key="why-para4"
                                className="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">
                                {t('OURVALUE.PARAGRAPH1')}
                                {t('OURVALUE.PARAGRAPH3')}
                            </p>
                        </div>
                    </div>




                </div>
            </div>
        </div>
    )
}
