import React from "react"
import { useTranslation, } from "../intl/helper"
import { useLang } from '../intl/locals'
import { AboutScreen } from "./about"
import { Welcome } from "./Welcome"

export const HomeScreen = () => {
    console.log('render')
    const t = useTranslation()
    const lang = useLang()
    return (
        <>
           <div dir={lang === 'ar' ? 'rtl' : ''} >
            <div
                className="bg-image d-flex obj-fit-cover bg-image justify-content-center align-items-center "
                style={{
                    backgroundImage: `url(${require('../assets/images/globe.png')})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '70vh',
                    backgroundPosition: '50% 50%'
                }}
            >
                <h1 className=" my-1 display-1 text-center col-sm-10 text-white">

                    {t('Welcome.subtitle')}
                </h1>
            </div>
            <div className="container my-5">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <p i18n-key="welcome-para1"
                                className="mbr-text fs-5 mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">
                                {t('INTRO.DESCRIPTION')}
                            </p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <p
                                className="mbr-text fs-5  mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">
                                {t('WELCOME.DESCRIPTION2')}
                                {/* The company was founded in 2016 by experienced travel and tourism management experts to
                                provide the highest quality service to our customers. */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
