import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AboutScreen } from "./about";
import { ContactScreen } from "./contact";
import { HomeScreen } from "./home1";
import { NavBar } from "./Navigation";
import { Welcome } from "./Welcome";

export const Navigation = () => {

    return (



        <BrowserRouter >
            <Routes>
                <Route element={<>
                    <NavBar />
                </>}
                >
                    <Route path="/" element={
                        <>
                            <HomeScreen />
                        </>
                    } />
                    <Route path="/about" element={
                        <>
                            <AboutScreen />
                        </>
                    } />
                    <Route path="/welcome" element={
                        <>
                            <Welcome />
                        </>
                    } />
                    <Route path="/contact" element={
                        <>
                            <ContactScreen />
                        </>
                    } />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
